import { ComponentProps, useEffect, useState } from 'react'
import { InvestmentOpportunityExtended } from 'api/dto'
import clsx from 'clsx'
import {
  Carousel,
  InvestmentFullSizeCard,
  withErrorBoundaryAndLogging,
} from 'ui/components/content'
import { InvestmentsCarouselLoading } from './InvestmentsCarouselLoading'
import { InvestmentsEmptyState } from './InvestmentsEmptyState'
import { InvestmentsSection } from './InvestmentsSection'
import { InvestmentsSectionLoading } from './InvestmentsSectionLoading'
import { useInvestments } from './useInvestments'
import { useInvestmentsCount } from './useInvestmentsCount'
import { MainPageComponentProps } from '../ComponentProps'
import { useSlidesToShow } from '../useSlidesToShow'

type InvestmentsCarouselImplementationProps = MainPageComponentProps &
  Omit<ComponentProps<typeof InvestmentsSection>, 'count'>

function InvestmentsCarouselImplementation({
  isLoading,
  onReady,
  ...props
}: InvestmentsCarouselImplementationProps) {
  const slidesToShow = useSlidesToShow()

  const { data: investments, isLoading: isInvestmentsLoading } = useInvestments()
  const { data: investmentsCount, isLoading: isInvestmentsCountLoading } = useInvestmentsCount()

  useEffect(() => {
    if (!isInvestmentsLoading && !isInvestmentsCountLoading) {
      onReady?.()
    }
  }, [isInvestmentsLoading, isInvestmentsCountLoading])

  const isInProgress = isLoading || isInvestmentsLoading || isInvestmentsCountLoading

  if (isInProgress) {
    return (
      <InvestmentsSectionLoading>
        <InvestmentsCarouselLoading slidesToShow={slidesToShow} />
      </InvestmentsSectionLoading>
    )
  }

  const isEmpty = !investments?.length

  return (
    <InvestmentsSection count={investmentsCount} {...props}>
      {isEmpty ? (
        <InvestmentsEmptyState />
      ) : (
        <InvestmentsCardsCarousel investments={investments} slidesToShow={slidesToShow} />
      )}
    </InvestmentsSection>
  )
}

export const InvestmentsCarousel = withErrorBoundaryAndLogging(InvestmentsCarouselImplementation)

interface InvestmentsCardsCarouselProps {
  investments: InvestmentOpportunityExtended[]
  slidesToShow: number
}

function InvestmentsCardsCarousel({ investments, slidesToShow }: InvestmentsCardsCarouselProps) {
  const [slideIndex, setSlideIndex] = useState(0)

  return (
    <Carousel
      slidesToShow={slidesToShow}
      beforeSlide={(_, endSlideIndex) => {
        setSlideIndex(endSlideIndex)
      }}
    >
      {investments.map((investment, i) => (
        <InvestmentFullSizeCard
          key={investment.id}
          className={clsx(
            'block w-full sm:w-[276px]',
            // in case need to show more that one slide with some space between each
            // nuka carousel can't split free space between items automatically
            // take first showed slide and add growing relative shift for all next slides
            `relative`,
          )}
          style={{
            left: `${((i - slideIndex) % slidesToShow) * 8}px`,
          }}
          investment={investment}
        />
      ))}
    </Carousel>
  )
}
