import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { Card } from 'ui/components/content'
import { Link } from 'ui/components/navigation'

export interface InvestmentsSectionProps extends Omit<ComponentProps<typeof Card>, 'title'> {
  readonly count: number
}

export const InvestmentsSection: FC<InvestmentsSectionProps> = ({
  className,
  children,
  count,
  ...props
}) => {
  return (
    <Card className={clsx('flex flex-col gap-4', className)} {...props}>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <div className="text-deep-teal-900 text-base font-bold leading-5">Open Investments</div>

          {count && (
            <div className="text-deep-gray-600 basic-text-xs bg-deep-gray-100 h-5 rounded-full px-2.5 py-0.5 font-semibold leading-4">
              {count}
            </div>
          )}
        </div>

        {count && (
          <Link
            href="/investments"
            className="text-nowrap text-sm font-semibold text-orange-600 hover:text-orange-800"
          >
            View All
          </Link>
        )}
      </div>
      {children}
    </Card>
  )
}
