import { FC, useEffect } from 'react'
import { NominatedMember } from 'api/dto'
import { useAxios } from 'api/lib'
import { Card, withErrorBoundaryAndLogging } from 'ui/components/content'
import { NominatedMembersList } from 'ui/components/content/NominatedMembers'
import { MainPageComponentProps } from '../ComponentProps'

const useNominatedMembers = (userId: number) => {
  return useAxios<NominatedMember[]>(`/api/users/${userId}/nominated-members`)
}

const NominationsImplementation: FC<MainPageComponentProps & { userId: number }> = ({
  isLoading,
  onReady,
  userId,
}) => {
  const { data: nominatedMembers, isLoading: isNominatedMembersLoading } =
    useNominatedMembers(userId)

  useEffect(() => {
    if (!isNominatedMembersLoading) {
      onReady?.()
    }
  }, [isNominatedMembersLoading])

  return (
    <Card className="hidden lg:block">
      <NominatedMembersList nominatedMembers={nominatedMembers} isLoading={isLoading} />
    </Card>
  )
}

export const Nominations: FC<MainPageComponentProps & { userId: number }> =
  withErrorBoundaryAndLogging(NominationsImplementation)
