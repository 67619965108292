/* istanbul ignore file */

import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import withAmityUiKitProvider, { useAmityProviderStore } from '../withAmityUiKitProvider'

const AmityUiKitFeed: any = dynamic(
  () => import('@meetperry/amity-uikit').then((m) => m.AmityUiKitFeed),
  {
    ssr: false,
  },
)

export const AmityUiKitFeedWithProvider = withAmityUiKitProvider((props): any => {
  const { connected } = useAmityProviderStore()

  useEffect(() => {
    if (connected) {
      props.onLoaded?.()
    }
  }, [connected])

  return (
    <>
      {/* UI styles adjusted to mimic to card on main page */}

      <style jsx global>{`
        .amity-uikit-feed a {
          text-decoration: underline;
        }
        .amity-uikit-feed .post,
        .amity-uikit-feed .postComposeBar {
          border-radius: 8px;
          box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
          border-top: none;
          border-bottom: none;
        }

        .amity-uikit-feed .post:not(:first-child) {
          margin-top: 24px;
        }
        @media (min-width: 1280px) /* xl breakpoint */ {
          .amity-uikit-feed .post:not(:first-child) {
            margin-top: 16px;
          }
        }
      `}</style>
      {connected && <AmityUiKitFeed className="amity-uikit-feed" {...props} />}
    </>
  )
})
