import { ComponentProps } from 'react'
import { cn } from 'ui/lib'
import { Button } from 'ui/src/components/forms'

type NominateMemberProps = ComponentProps<typeof Button>

export function NominateMember({ className, ...props }: NominateMemberProps) {
  return (
    <Button
      className={cn('text-nowrap', className)}
      uiType="primary"
      href="/nominations"
      data-trackclick={JSON.stringify({
        action: 'Clicked Nominate Member - Nominations Leaderboard',
      })}
      {...props}
    >
      Nominate a member
    </Button>
  )
}
