import { InvestmentOpportunityExtended } from 'api/dto'
import { useAxios } from 'api/lib'

const MAX_INVESTMENTS_IN_COMPONENT = 4

export function useInvestments(limit = MAX_INVESTMENTS_IN_COMPONENT) {
  // URL is the unique key for the internal SWR cache. If we move "limit" to params,
  // URL will exactly match the one on the All Investment Opportunities page. This leads to
  // caching response between these two pages. To avoid this we make "limit" part of the key.
  return useAxios<InvestmentOpportunityExtended[]>(
    `/api/investment-opportunities?limit=${limit}&stage=core`,
  )
}
