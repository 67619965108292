import { ComponentProps, useEffect } from 'react'
import { InvestmentOpportunityExtended } from 'api/dto'
import { InvestmentFullSizeCard, withErrorBoundaryAndLogging } from 'ui/components/content'
import { InvestmentsEmptyState } from './InvestmentsEmptyState'
import { InvestmentsLoading } from './InvestmentsLoading'
import { InvestmentsSection } from './InvestmentsSection'
import { InvestmentsSectionLoading } from './InvestmentsSectionLoading'
import { useInvestments } from './useInvestments'
import { useInvestmentsCount } from './useInvestmentsCount'
import { MainPageComponentProps } from '../ComponentProps'

type InvestmentsImplementationProps = MainPageComponentProps &
  Omit<ComponentProps<typeof InvestmentsSection>, 'count'>

function InvestmentsImplementation({
  isLoading,
  onReady,
  ...props
}: InvestmentsImplementationProps) {
  const { data: investments, isLoading: isInvestmentsLoading } = useInvestments()
  const { data: investmentsCount, isLoading: isInvestmentsCountLoading } = useInvestmentsCount()

  useEffect(() => {
    if (!isInvestmentsLoading && !isInvestmentsCountLoading) {
      onReady?.()
    }
  }, [isInvestmentsLoading, isInvestmentsCountLoading])

  const isInProgress = isLoading || isInvestmentsLoading || isInvestmentsCountLoading

  if (isInProgress) {
    return (
      <InvestmentsSectionLoading>
        <InvestmentsLoading />
      </InvestmentsSectionLoading>
    )
  }

  const isEmpty = !investments?.length

  return (
    <InvestmentsSection count={investmentsCount} {...props}>
      {isEmpty ? <InvestmentsEmptyState /> : <InvestmentsCards investments={investments} />}
    </InvestmentsSection>
  )
}

export const Investments = withErrorBoundaryAndLogging(InvestmentsImplementation)

interface InvestmentsCardsProps {
  investments: InvestmentOpportunityExtended[]
}

function InvestmentsCards({ investments }: InvestmentsCardsProps) {
  return (
    <div className="flex flex-col gap-4">
      {investments.map((investment) => (
        <InvestmentFullSizeCard className="block" key={investment.id} investment={investment} />
      ))}
    </div>
  )
}
