import { FC, useEffect } from 'react'
import { Announcement } from 'api/cms'
import { useAxios } from 'api/lib'
import { AnnouncementsCarousel, withErrorBoundaryAndLogging } from 'ui/components/content'
import { MainPageComponentProps } from '../ComponentProps'

const useAnnouncement = () => {
  return useAxios<Announcement[]>(`/api/announcements`)
}

const AnnouncementsImplementation: FC<MainPageComponentProps> = ({ isLoading, onReady }) => {
  const { data: announcements, isLoading: isAnnouncementsLoading } = useAnnouncement()

  useEffect(() => {
    if (!isAnnouncementsLoading) {
      onReady?.()
    }
  }, [isAnnouncementsLoading])

  return (
    <>
      {!isLoading && !isAnnouncementsLoading && (
        <AnnouncementsCarousel announcements={announcements} />
      )}
    </>
  )
}

export const Announcements = withErrorBoundaryAndLogging(AnnouncementsImplementation)
