import { FC, useState } from 'react'
import { useSession } from 'auth'
import { useFlag, LDFlag } from 'launchdarkly'
import { cn } from 'ui/lib'
import { DefaultLayout } from '@perry/app/components/layouts'
import { AmityFeed } from './AmityFeed'
import { Announcements } from './Announcements'
import { Events } from './Events'
import { Groups } from './Groups'
import { NominateMember, SearchContainer } from './Header'
import { Investments, InvestmentsCarousel } from './Investments'
import { Nominations } from './Nominations'
import { NominatorsLeaderboard } from './NominatorsLeaderboard'
import { Welcome } from './Welcome'

export const Main: FC = () => {
  const displayNominatorsLeaderboard = useFlag(LDFlag.DisplayNominatorsLeaderboard)

  const [isNominatedMembersLoading, setIsNominatedMembersLoading] = useState(true)

  // ToDo: useState for isNominationsLeaderboardLoading should be set to true
  // when the displayNominatorsLeaderboard removed. The current setting
  // preventing Nominators Leaderboard from affecting the rest of the page.
  const [isNominationsLeaderboardLoading, setIsNominationsLeaderboardLoading] = useState(false)
  const [isProfileLoading, setIsProfileLoading] = useState(true)
  const [isInvestmentsLoading, setInvestmentsLoading] = useState(true)
  const [isInvestmentsCarouselLoading, setInvestmentsCarouselLoading] = useState(true)
  const [isEventsLoading, setEventsLoading] = useState(true)

  const { user, isLoading: isUserLoading } = useSession()

  // Sync loading state
  const isLoading =
    isProfileLoading ||
    isNominatedMembersLoading ||
    isNominationsLeaderboardLoading ||
    isUserLoading ||
    isInvestmentsLoading ||
    isInvestmentsCarouselLoading ||
    isEventsLoading

  return (
    <DefaultLayout
      className={cn(
        'mx-auto flex w-full flex-col gap-6 px-4 py-6 ',
        'sm:max-w-[632px] sm:py-8',
        'xl:grid xl:max-w-[1296px] xl:grid-cols-4 xl:grid-rows-[min-content_auto] xl:gap-4',
      )}
      title="meetperry | Home"
    >
      <div
        className={cn(
          'flex w-full flex-col gap-6',
          'xl:col-span-4 xl:flex-row xl:items-center xl:justify-between xl:gap-4',
        )}
      >
        <Welcome
          className="shrink-0"
          user={user}
          isLoading={isLoading}
          onReady={setIsProfileLoading}
        />
        <div className={cn('hidden w-full gap-x-4', 'lg:flex lg:items-center')}>
          <SearchContainer className="h-[38px] xl:w-48" />
          <NominateMember />
        </div>
      </div>

      {/* Left Column */}
      <div className="hidden xl:col-start-1 xl:block">
        <Investments isLoading={isLoading} onReady={() => setInvestmentsLoading(false)} />
      </div>
      {/* Middle (Main) Column */}
      <div
        className={cn('col-span-2 col-start-1 flex flex-col gap-6', 'xl:gap-4', 'xl:col-start-2')}
      >
        {/**
         * We intentionally do not handle announcements ready state
         * since this section is optional and should not affect the rest of the page
         */}
        <Announcements isLoading={isLoading} />
        <InvestmentsCarousel
          className="xl:hidden"
          isLoading={isLoading}
          onReady={() => setInvestmentsCarouselLoading(false)}
        />
        {/**
         * We intentionally do not handle AmityFeed ready state
         * and make its loading state independent
         * since this section is backed by third party service
         * and should not affect the rest of the page
         */}
        <AmityFeed />
      </div>
      {/* Right Column */}
      <div className={cn('flex flex-col gap-6', 'md:gap-4', 'xl:col-start-4')}>
        {displayNominatorsLeaderboard && (
          <NominatorsLeaderboard
            isLoading={isLoading}
            onReady={() => setIsNominationsLeaderboardLoading(false)}
            className="hidden sm:block"
          />
        )}
        <Nominations
          userId={user.id}
          isLoading={isLoading}
          onReady={() => setIsNominatedMembersLoading(false)}
        />
        <Events onReady={() => setEventsLoading(false)} isLoading={isLoading} />
        {/*
         * Intentionally stopped using information regarding groups loading state
         * because it is provided by third party service Amity, slowest to load
         * and should not affect the rest of the page.
         * It speeds up the page load time about 3 times.
         */}
        <Groups isLoading={isLoading} />
      </div>
    </DefaultLayout>
  )
}
