import { ComponentProps, FC } from 'react'
import { times } from 'lodash'
import { InvestmentFullSizeCardLoading } from 'ui/components/content'
import { cn } from 'ui/lib'

export const InvestmentsLoading: FC<ComponentProps<'div'>> = ({ className, ...rest }) => {
  return (
    <div className={cn('flex flex-col gap-4', className)} {...rest}>
      {times(4, (i) => (
        <InvestmentFullSizeCardLoading key={i} />
      ))}
    </div>
  )
}
